.fab_input {
    @apply
    border-2
    bg-opacity-50
    flex
    align-middle
    justify-start
    duration-100
    border-b-2
    border-gray-300
    outline-none
    p-0.5
    focus:border-fab_mint_green-200
    disabled:bg-gray-200
}
.fab_button {
    @apply
    min-h-6
    duration-100
    shadow-md
    rounded-md
}
.fab_pagination_button {
    @apply
    min-h-6
    w-12
    duration-100
    shadow-md
    border-2
    border-transparent
    bg-fab_congress-blue-500
    hover:bg-fab_congress-blue-400
    text-white
    text-lg
}
.button_full {
    @apply
    border-2
    border-transparent
    bg-fab_congress-blue-500
    hover:bg-fab_congress-blue-400
    text-white
    font-bold
}
.button_light {
    @apply
    border-2
    border-fab_congress-blue-400
    hover:bg-fab_congress-blue-500
    text-black
    font-bold
}

.fab_button_input {
    @apply
    file:p-3
    file:min-h-6
    file:bg-fab_congress-blue-500
    file:duration-100
    file:shadow-md
    file:border-0
    file:hover:bg-fab_congress-blue-400
    file:text-white
}
@keyframes modal-fade-in {
    0% {
        opacity: 0;
        transform: scale(0.9);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}
@keyframes modal-back-fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.modal_container {
    @apply
    fixed
    inset-0
    z-10
    w-screen
    h-screen
    overflow-y-auto
    bg-gray-500
    bg-opacity-20
    flex
    justify-center;
    animation: modal-back-fade-in 0.15s ease-in;
}

.modal_content {
    animation: modal-fade-in 0.15s ease-in;
}
.bg-fab_app_container {
    @apply bg-white bg-opacity-50 overflow-y-auto;
    scrollbar-width: none;
    background-image: url("../../../public/lighter_graphwhite.jpg");
    background-size: cover;
}

@keyframes scaleIn {
    0% { width: 0; margin-left: 0; }
    100% { margin-left: 0; }
}

.animate-slideIn {
    animation: scaleIn 0.25s ease;
}

/*CSS ONLY NO COMPONENT YET */
.fab_select {
    position: relative;
    display: inline-block;
    width: 200px;
}

.fab_select-items {
    position: absolute;
    background-color: #f9f9f9;
    overflow: auto;
    z-index: 99;
    display: none;
    border: 1px solid #ddd;
}

.show-flex {
    display: flex !important;
}

.fab_select-items div {
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.fab_select-items div:hover {
    background-color: #f1f1f1;
}

.fab_select-selected {
    background-color: #ddd;
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
}