@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #DFDBE5;
  background-size: contain;
  background-position-x: right;
  /*background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='152' height='152' viewBox='0 0 152 152'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='temple' fill='%239C92AC' fill-opacity='0.4'%3E%3Cpath d='M152 150v2H0v-2h28v-8H8v-20H0v-2h8V80h42v20h20v42H30v8h90v-8H80v-42h20V80h42v40h8V30h-8v40h-42V50H80V8h40V0h2v8h20v20h8V0h2v150zm-2 0v-28h-8v20h-20v8h28zM82 30v18h18V30H82zm20 18h20v20h18V30h-20V10H82v18h20v20zm0 2v18h18V50h-18zm20-22h18V10h-18v18zm-54 92v-18H50v18h18zm-20-18H28V82H10v38h20v20h38v-18H48v-20zm0-2V82H30v18h18zm-20 22H10v18h18v-18zm54 0v18h38v-20h20V82h-18v20h-20v20H82zm18-20H82v18h18v-18zm2-2h18V82h-18v18zm20 40v-18h18v18h-18zM30 0h-2v8H8v20H0v2h8v40h42V50h20V8H30V0zm20 48h18V30H50v18zm18-20H48v20H28v20H10V30h20V10h38v18zM30 50h18v18H30V50zm-2-40H10v18h18V10z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.steps-container {
  transition: all 0.7s ease-in-out;
}

.step-0 {
  opacity: 1;
  transform: translateX(0%);
}

.step-1 {
  opacity: 1;
  transform: translateX(-33.3%);
}

.step-2 {
  opacity: 1;
  transform: translateX(-66.6%);
}
.fab_login_container {
  /*background-image: url("assets/backLogin.png");*/
  background-size: cover;
  @apply bg-fab_congress-blue-800
}
.panel_page_entete {
  font-weight: bold;
  @apply w-full flex text-center border-black underline underline-offset-2 bg-opacity-55 mb-2
}
.fab_dashboard_element {
  @apply h-48 p-10 bg-gray-200 shadow-xl border-2 rounded border-gray-500 text-center flex flex-col items-center hover:border-fab_congress-blue-500 text-lg
}
.fab_editor_modal {
  @apply bg-white w-full rounded-md m-auto text-center shadow-2xl text-xl overflow-hidden
}
.fab_editor_modal_input {
  @apply flex gap-5 align-middle justify-center w-2/3 m-auto
}
html {
  @apply h-full;
}
body {
  @apply h-full;
}
#root {
  @apply h-full;
}
.fab_navbar
{
  @apply w-2/12 flex flex-col bg-fab_mint_green-900 justify-start justify-items-start select-none overflow-y-scroll overflow-x-hidden;
  scrollbar-width: none;
}
.splash {
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../public/splash.png");
}
/* styles.css */
.tag {
  @apply inline-flex items-center px-3 py-1.5 rounded-full text-sm font-medium;
}

/* Couleurs personnalisées */
.tag-blue {
  @apply bg-blue-100 text-blue-800;
}

.tag-green {
  @apply bg-green-100 text-green-800;
}

.tag-red {
  @apply bg-red-100 text-red-800;
}

.tag-yellow {
  @apply bg-yellow-100 text-yellow-800;
}
